import React from "react";
import { Navigate } from "react-router-dom";
import Home from "../../../pages/Home/index";
import About from "../../../pages/About/index";
import Download from "../../../pages/Download";
import Marketplace from "../../../pages/Marketplace";
import Event from "../../../pages/Event";
import Notice from "../../../pages/Support/Notice";
import Faq from "../../../pages/Support/Faq";
import Qna from "../../../pages/Support/Qna";
import Tos from "../../../pages/Tos/index";
import Privacy from "../../../pages/Privacy";
import NotFound from "../../../pages/NotFound";
import NodeRPC from "../../../pages/NodeRPC";
import MyPage from "../../../pages/Mypage";
import ResetPassword from "../../Mypage/pages/ResetPassword";
import HeuguPass from "../../Mypage/pages/HeuguPass";
import Cart from "../../../pages/Cart";
import OrderCompleted from "../../../pages/OrderCompleted";
import { PrivateRoute } from "../../../PrivateRoute";
import { isProduction } from "../../../utils/isProduction";

let sm = [
  { breadcrumb: "홈", path: "/", element: <Home /> },
  { breadcrumb: "소개", name: "소개", navigation: true, path: "/about", element: <About /> },
  { breadcrumb: "다운로드", name: "다운로드", navigation: true, path: "/download", element: <Download /> },
  { breadcrumb: "장터", name: "장터", navigation: true, path: "/marketplace", element: <Marketplace /> },
  { breadcrumb: "노드RPC", name: "노드RPC", navigation: true, path: "/node", element: <NodeRPC /> },
  { breadcrumb: "이벤트", name: "이벤트", navigation: true, path: "/event", element: <Event /> },
  {
    breadcrumb: "고객지원",
    name: "고객지원",
    navigation: true,
    path: "support",
    children: [
      { breadcrumb: "공지사항", name: "공지사항", navigation: true, path: "notice", element: <Notice /> },
      { breadcrumb: "FAQ", name: "FAQ", navigation: true, path: "faq", element: <Faq /> },
      { breadcrumb: "1:1 문의", name: "1:1 문의", navigation: true, path: "qna", element: <Qna /> },
      { breadcrumb: "고객지원", path: "", element: <Navigate to="/support/notice" /> },
    ],
  },
  {
    breadcrumb: "마이페이지",
    name: "마이페이지",
    navigation: true,
    path: "mypage",
    hide: true,
    children: [
      {
        breadcrumb: "내 정보",
        name: "내 정보",
        navigation: true,
        path: "info",
        element: (
          <PrivateRoute>
            <MyPage />
          </PrivateRoute>
        ),
      },
      {
        breadcrumb: "비밀번호 변경",
        name: "비밀번호 변경",
        navigation: true,
        path: "reset_password",
        element: (
          <PrivateRoute>
            <ResetPassword />
          </PrivateRoute>
        ),
      },
      {
        breadcrumb: "흑우촌 패스",
        name: "흑우촌 패스",
        navigation: true,
        path: "pass",
        element: (
          <PrivateRoute>
            <HeuguPass />
          </PrivateRoute>
        ),
      },
    ],
  },
  { breadcrumb: "이용약관", name: "이용약관", navigation: false, path: "/tos", element: <Tos /> },
  { breadcrumb: "개인정보처리방침", name: "개인정보처리방침", navigation: false, path: "/privacy", element: <Privacy /> },
  {
    path: "/cart",
    element: (
      <PrivateRoute>
        <Cart />
      </PrivateRoute>
    ),
  },
  {
    path: "/order-completed",
    element: (
      <PrivateRoute>
        <OrderCompleted />
      </PrivateRoute>
    ),
  },
  { path: "*", element: <NotFound /> },
];

if (isProduction) {
  sm = [
    { breadcrumb: "홈", path: "/", element: <Home /> },
    { breadcrumb: "소개", name: "소개", navigation: true, path: "/about", element: <About /> },
    { breadcrumb: "다운로드", name: "다운로드", navigation: true, path: "/download", element: <Download /> },
    { breadcrumb: "노드RPC", name: "노드RPC", navigation: true, path: "/node", element: <NodeRPC /> },
    { breadcrumb: "이벤트", name: "이벤트", navigation: true, path: "/event", element: <Event /> },
    {
      breadcrumb: "고객지원",
      name: "고객지원",
      navigation: true,
      path: "support",
      children: [
        { breadcrumb: "공지사항", name: "공지사항", navigation: true, path: "notice", element: <Notice /> },
        { breadcrumb: "FAQ", name: "FAQ", navigation: true, path: "faq", element: <Faq /> },
        { breadcrumb: "1:1 문의", name: "1:1 문의", navigation: true, path: "qna", element: <Qna /> },
        { breadcrumb: "고객지원", path: "", element: <Navigate to="/support/notice" /> },
      ],
    },
    {
      breadcrumb: "마이페이지",
      name: "마이페이지",
      navigation: true,
      path: "mypage",
      hide: true,
      children: [
        {
          breadcrumb: "내 정보",
          name: "내 정보",
          navigation: true,
          path: "info",
          element: (
            <PrivateRoute>
              <MyPage />
            </PrivateRoute>
          ),
        },
        {
          breadcrumb: "비밀번호 변경",
          name: "비밀번호 변경",
          navigation: true,
          path: "reset_password",
          element: (
            <PrivateRoute>
              <ResetPassword />
            </PrivateRoute>
          ),
        },
        {
          breadcrumb: "흑우촌 패스",
          name: "흑우촌 패스",
          navigation: true,
          path: "pass",
          element: (
            <PrivateRoute>
              <HeuguPass />
            </PrivateRoute>
          ),
        },
      ],
    },
    { breadcrumb: "이용약관", name: "이용약관", navigation: false, path: "/tos", element: <Tos /> },
    { breadcrumb: "개인정보처리방침", name: "개인정보처리방침", navigation: false, path: "/privacy", element: <Privacy /> },
    {
      path: "/cart",
      element: (
        <PrivateRoute>
          <Cart />
        </PrivateRoute>
      ),
    },
    {
      path: "/order-completed",
      element: (
        <PrivateRoute>
          <OrderCompleted />
        </PrivateRoute>
      ),
    },
    { path: "*", element: <NotFound /> },
  ];
}

export const sitemap = sm;
