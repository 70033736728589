import { Link } from "react-router-dom";
const FooterCopyright = () => {
  return (
    <div className="footer-bottom">
      <div className="row">
        <div className="column width-12">
          <div className="footer-bottom-inner">
            <p className="copyright pull-left clear-float-on-mobile">
              Copyright 2021 - 2025.
              <Link target="_blank" to="https://www.wiselight.kr/" rel="noreferrer">
                {" "}
                와이즈라이트{" "}
              </Link>
              All rights reserved.
            </p>
            <Link to="#" className="scroll-to-top pull-right clear-on-mobile" data-no-hide>
              Back Top
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCopyright;
