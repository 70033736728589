import { Link } from "react-router-dom";
import Logo from "../../../images/logo.png";

const FooterLogo = () => {
  return (
    <div className="widget">
      <div className="footer-logo">
        <Link to="/">
          <img src={Logo} alt="흑우촌 로고" />
        </Link>
      </div>
      <address style={{ marginTop: "-15px" }}>
        상호 : 와이즈라이트
        <br />
        대표자명 : 김현욱
        <br />
        주소 : 서울시 성동구 상원12길 34, 318호
        <br />
        전화 : 070-8989-9092
        <br />
        사업자등록번호 : 441-35-01084
        <br />
        통신판매업신고번호 :{" "}
        <Link target="_blank" to="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=4413501084">
          제 2024-서울성동-2153
        </Link>
        <br />
        호스팅제공자 : 아마존웹서비스
      </address>
    </div>
  );
};

export default FooterLogo;
