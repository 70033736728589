import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { buttonStyle } from "../../constants/colors";

const BannerSection = () => {
  const [bannerHeight, setBannerHeight] = useState(600);
  const [scrollPosition, setScrollPosition] = useState(0);
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const startButtonUrl = authenticated ? "hop://robust.heuguchon.com" : "/register";

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      let newHeight;

      if (newWidth >= 1080) {
        newHeight = newWidth * 0.5 + 121.5;
      } else {
        newHeight = newWidth * 0.5;
      }

      if (newHeight >= 450 && newHeight <= 600) {
        setBannerHeight(newHeight);
      } else if (newHeight < 450) {
        setBannerHeight(450);
      } else {
        setBannerHeight(600);
      }
    };

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const calculateOpacity = () => {
    const opacity = Math.max(1 - scrollPosition / 600, 0);
    return opacity;
  };

  const calculateTransform = () => {
    const translateY = Math.min(scrollPosition / 3, 200);
    return `translateY(-${translateY}px)`;
  };

  const textStyle = {
    opacity: calculateOpacity(),
    transform: calculateTransform(),
    transition: "opacity 0s, transform 0s",
  };
  return (
    <>
      <div id="home" className="section-block featured-media tm-slider-parallax-container" style={{ height: `${bannerHeight}px`, width: "100%" }}>
        <div className="tm-slider-container full-width-slider show-on-hover">
          <ul className="tms-slides">
            <li className="tms-slide video-bkg-loaded slide-style" style={{ height: `${bannerHeight}px`, width: "100%" }}>
              <div className="tms-content">
                <div className="tms-content-inner center left-on-mobile v-align-middle" style={textStyle}>
                  <div className="row">
                    <div className="column width-8 offset-2 opacity-08">
                      <h1 className="weight-light color-white mb-5 no-scale banner-h1 caption-style">메타버스 기반 가상자산 커뮤니티</h1>
                      <div className="clear"></div>
                      <h1 className="weight-semi-bold color-white mb-40 no-scale banner-h1 caption-style">흑우촌</h1>
                      <div className="clear"></div>
                      <Link
                        to={startButtonUrl}
                        className="button rounded large text-small text-uppercase shadow lightbox-link no-scale caption-style hover-717dfb"
                        style={buttonStyle}
                      >
                        시작하기
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iframe-container">
                <video loop muted autoPlay>
                  <source src="https://www.heuguchon.com/static/media/banner.mp4"/>
                </video>
              </div>
              <div className="tms-overlay" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)", opacity: "0" }}></div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default BannerSection;
