import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import PlanCard from "../PlanCard";
import PassAgreement from "./PassAgreement";
import PaymentContent from "./PaymentContent";
import AfterPayment from "./AfterPayment";
import { authInstance } from "../../../store/api/baseInstance";
import { colors, iconStyle } from "../../../constants/colors";

const loadingStyle = {
  ...iconStyle,
  border: `1px solid ${colors.primary}`,
};

const PassContent = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [isPlanSelected, setIsPlanSelected] = useState(false);
  const [selectedPlanPriceCode, setSelectedPlanPriceCode] = useState(null);
  const [subscribeId, setSubscribeId] = useState(null);
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [annualPlans, setAnnualPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      order_id: params.get("order_id"),
      order_code: params.get("order_code"),
      status: params.get("status"),
    };
  };

  const { order_id, order_code, status } = getQueryParams(location.search);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authInstance.get("/pass");
        setSubscribeId(response.data.id);
      } catch (error) {
        console.error("구독 정보를 가져오는데 실패하였습니다: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const getPlansData = async () => {
      try {
        const response = await authInstance.get("/pass/plans");
        const plans = response.data.plans;

        const monthly = plans
          .filter((plan) => plan.recurring.interval === "MONTH")
          .map((plan) => ({
            priceCode: plan.priceCode,
            title: plan.planName,
            price: plan.price.toLocaleString(),
            interval: "월간",
            description: plan.planDescription,
            gradientClass: "bkg-gradient-royal-garden color-white",
          }));

        const annual = plans
          .filter((plan) => plan.recurring.interval === "YEAR")
          .map((plan) => ({
            priceCode: plan.priceCode,
            title: plan.planName,
            price: plan.price.toLocaleString(),
            interval: "연간",
            description: plan.planDescription,
            gradientClass: "bkg-gradient-royal-garden color-white",
          }));

        setMonthlyPlans(monthly);
        setAnnualPlans(annual);
      } catch (error) {
        console.error("Error fetching plans data:", error);
      }
    };

    getPlansData();
  }, []);

  const handleTabChange = (tabIndex, event) => {
    event.preventDefault();
    setActiveTab(tabIndex);
  };

  const handlePlanSelect = (planPriceCode) => {
    setIsPlanSelected(true);
    setSelectedPlanPriceCode(planPriceCode);
  };

  const renderPlans = (plans) => (
    <div className="tab-content pt-30">
      <div className="row flex boxes two-columns-on-tablet">
        {plans.map((plan, index) => (
          <PlanCard
            key={index}
            planTitle={plan.title}
            price={plan.price}
            interval={plan.interval}
            description={plan.description}
            gradientClass={plan.gradientClass}
            onSelect={() => handlePlanSelect(plan.priceCode)}
          />
        ))}
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="section-block replicable-content bkg-grey-ultralight">
        <div className="row">
          <div className="column width-12 center">
            <span className="icon-circled icon-cycle medium border-dribbble pulsate" style={loadingStyle} />
          </div>
        </div>
      </div>
    );
  }

  if (order_id && order_code && status) {
    return <AfterPayment order_id={order_id} order_code={order_code} status={status} />;
  }

  return subscribeId !== null ? (
    <PaymentContent subscribeId={subscribeId} />
  ) : (
    <div className="section-block replicable-content bkg-grey-ultralight">
      {isPlanSelected && <PassAgreement priceCode={selectedPlanPriceCode} />}
      {!isPlanSelected && (
        <>
          <div className="row">
            <div className="column width-12 center">
              <h2>CCT를 위한 탁월한 선택</h2>
              <p className="lead">흑우촌 패스는 현재 준비 중입니다</p>
            </div>
          </div>
          <div className="row">
            <div className="column width-12">
              <div className="tabs rounded button-nav pill small bordered center">
                <ul className="tab-nav">
                  <li className={activeTab === 1 ? "active" : ""} onClick={(e) => handleTabChange(1, e)}>
                    <Link to="#tabs-1-pane-1">월간 플랜</Link>
                  </li>
                  <li className={activeTab === 2 ? "active" : ""} onClick={(e) => handleTabChange(2, e)}>
                    <Link to="#tabs-1-pane-2">연간 플랜</Link>
                  </li>
                </ul>
                <div className="tab-panes">
                  {activeTab === 1 && (
                    <div id="tabs-1-pane-1" className="active animate animate-in">
                      {renderPlans(monthlyPlans)}
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div id="tabs-1-pane-2" className="active animate animate-in">
                      {renderPlans(annualPlans)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PassContent;
