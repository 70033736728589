import React, { useState, useEffect } from "react";
import TitleContainer from "../../components/common/Layout/TitleContainer";
import SelectCategory from "../../components/Event/SelectCategory";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { publicInstance } from "../../store/api/baseInstance";
import NoThumbnail from "../../images/blog/no_thumbnail.jpeg";
import { TbMoodEmpty } from "react-icons/tb";

const getDynamicWidth = (windowWidth) => {
  if (windowWidth >= 1155) {
    return 570;
  } else if (windowWidth >= 1141) {
    return 569 - (1154 - windowWidth);
  } else if (windowWidth >= 975) {
    return 480;
  } else if (windowWidth >= 961) {
    return 479 - (974 - windowWidth) * 2;
  } else if (windowWidth >= 775) {
    return 380;
  } else if (windowWidth >= 770) {
    return 379 - (773 - windowWidth);
  } else if (windowWidth === 768) {
    return 300;
  } else {
    return 300;
  }
};

const getDynamicHeight = (windowWidth) => {
  if (windowWidth >= 1155) {
    return 385;
  } else if (windowWidth >= 1141) {
    return 384 - (1154 - windowWidth);
  } else if (windowWidth >= 975) {
    return 380;
  } else if (windowWidth >= 961) {
    return 379 - (974 - windowWidth) * 2;
  } else if (windowWidth >= 775) {
    return 258;
  } else if (windowWidth >= 770) {
    return 357 - (773 - windowWidth);
  } else if (windowWidth === 768) {
    return 205;
  } else {
    return 205;
  }
};

const categoryToStatus = {
  모두: "",
  예정: "UPCOMING",
  진행중: "ONGOING",
  마감: "ENDED",
};

const Event = () => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [filteredCategory, setFilteredCategory] = useState("진행중");
  const itemsPerPage = 10;
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const selectedEvent = filteredProducts.find((item) => item.id === parseInt(id));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const status = categoryToStatus[filteredCategory];
        const response = await publicInstance.get(`/events${status ? `?status=${status}` : ""}`);
        const events = response.data.contents;
        setFilteredProducts(events);
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
    };

    fetchEvents();
  }, [filteredCategory]);

  useEffect(() => {
    const handleResize = () => setBrowserWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLinkClick = (e, newPage) => {
    e.preventDefault();
    if (newPage < 1 || newPage > pageCount) {
      return;
    }
    setPage(newPage);
  };

  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);
  const paginatedProducts = filteredProducts.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  let contents;
  if (id && selectedEvent) {
    contents = (
      <>
        <div className="section-block clearfix pt-0 pb-0 bkg-grey-ultralight">
          <div className="row">
            <div className="column width-10 offset-1 content-inner blog-single-post">
              <article className="post">
                <div className="post-content with-background">
                  <h2 className="post-title center">{selectedEvent.title}</h2>
                  <div className="post-info center">
                    <span className="post-date">
                      {selectedEvent.startDate} ~ {selectedEvent.endDate}
                    </span>
                  </div>
                  <div className="post-content" dangerouslySetInnerHTML={{ __html: selectedEvent?.content }} />
                </div>
              </article>
            </div>
          </div>
        </div>

        <div className="section-block pagination-3 portfolio bkg-grey-ultralight">
          <div className="row">
            <div className="column width-10 offset-1">
              <ul>
                <li>
                  <Link className="back-to-grid fade-location" to="#" onClick={() => navigate(-1)}>
                    목록
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    contents = (
      <>
        <SelectCategory setFilteredCategory={setFilteredCategory} filteredProducts={filteredProducts} />
        <div
          className="section-block content-inner blog-masonry grid-container fade-in-progressively pt-1- pb-0 bkg-grey-ultralight masonry masonry-set-dimensions"
          data-layout-mode="masonry"
          data-grid-ratio="1.5"
          data-animate-resize
          data-set-dimensions
          data-animate-resize-duration="600"
          data-as-bkg-image
        >
          <div className="row">
            <div className="column width-12">
              <div className="row grid content-grid-2 event-card-container" style={{ visibility: "visible", height: "fit-content" }}>
                {paginatedProducts.length === 0 ? (
                  <div style={{ width: "250px", margin: "0 auto", textAlign: "center" }}>
                    <TbMoodEmpty size={100} style={{ marginTop: "50%" }} />
                    <p style={{ fontSize: "30px" }}>이벤트 준비중입니다.</p>
                  </div>
                ) : (
                  paginatedProducts.map((item, index) => (
                    <div
                      className="grid-item animate-in"
                      style={{ width: `${getDynamicWidth(browserWidth)}px`, height: `${getDynamicHeight(browserWidth)}px` }}
                      key={index}
                    >
                      <div
                        className="thumbnail rounded overlay-fade-img-scale-in"
                        data-hover-easing="easeInOut"
                        data-hover-speed="700"
                        data-hover-bkg-color="#000000"
                        data-gradient
                        data-gradient-spread="70%"
                        data-hover-bkg-opacity="0.75"
                      >
                        <Link className="overlay-link" to={`/event?id=${item.id}`}>
                          {item.thumbnailUrl ? (
                            <img
                              src={item.thumbnailUrl}
                              alt={item.alt}
                              className="background-image background-cover"
                              style={{ objectFit: "cover", width: "100%", height: "100%" }}
                            />
                          ) : (
                            <img
                              src={NoThumbnail}
                              alt="이미지 준비중"
                              className="background-image background-cover"
                              style={{ objectFit: "cover", width: "100%", height: "100%" }}
                            />
                          )}
                          <span
                            className="overlay-info v-align-bottom center"
                            style={{
                              backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, 0.75) 0%, transparent 70%)",
                              backgroundColor: "transparent",
                            }}
                          >
                            <span>
                              <span>
                                <span className="post-title event-card-title" style={{ fontWeight: "500" }}>
                                  {item.title}
                                </span>
                                <span className="post-info">
                                  <span className="post-date">
                                    {item.startDate} ~ {item.endDate}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </Link>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="section-block pagination-3 bkg-grey-ultralight">
          <div className="row">
            <div className="column width-15">
              <ul>
                <li>
                  <Link
                    className={`pagination-previous ${page === 1 ? "disabled" : ""}`}
                    to="#"
                    onClick={(e) => handleLinkClick(e, page - 1)}
                    disabled={page === 1}
                  >
                    <span className="icon-left-open-mini"></span>
                  </Link>
                </li>
                {Array.from({ length: pageCount }, (_, i) => (
                  <li key={i + 1}>
                    <Link className={page === i + 1 ? "current" : undefined} to="#" onClick={(e) => handleLinkClick(e, i + 1)}>
                      {i + 1}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link
                    className={`pagination-next ${page === pageCount ? "disabled" : ""}`}
                    to="#"
                    onClick={(e) => handleLinkClick(e, page + 1)}
                    disabled={page === pageCount}
                  >
                    <span className="icon-right-open-mini"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <TitleContainer title="이벤트" description="흑우촌에서 진행하는 이벤트를 확인하실 수 있습니다" style={{ "bkg-grey-ultralight": true }} />
      {contents}
    </>
  );
};

export default Event;
